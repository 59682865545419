<template>
  <section class="d-flex flex-column">
    <PageHeader
      :title="$t('breadcrumb.EbooksConfig')"
      :sub-title="$t('sparkmembers.ebooks-config.views.index.sub-title')"
    >
      <template #badge>
        <hs-badge pill class="header-badge py-1 px-2 mt-3 mt-md-0">{{ product.title }}</hs-badge>
      </template>
    </PageHeader>

    <template>
      <section class="container-fluid">
        <div class="protection-container my-5 mx-md-4 p-md-4 p-1 rounded-lg flex-column">
          <h5 class="font-weight-bold mb-2">Proteção</h5>
          <p>
            Com proteção para PDF ativa, todos os PDFs anexados ao produto serão protegidos conforme as configurações
            definidas. Na seção de material complementar, os PDFs não terão preview, mas poderão ser baixados.
          </p>
          <div class="mt-3">
            <div class="d-flex flex-column">
              <MSwitch
                data-testid="protection-switch"
                v-model="isProtectionEnabled"
                label-title="Colocar marca d’ água com os dados do comprador em todas as páginas"
              />
              <div v-if="isProtectionEnabled" class="options-container pl-4 mt-4 d-flex">
                <div class="d-flex flex-column mr-0 mr-md-6">
                  <div>
                    <span>Tamanho da fonte</span>
                    <b-tabs pills card v-model="protectionOptions.size">
                      <b-tab
                        v-for="tab in tabs"
                        title-link-class="tab-item lh-150 font-weight-bold px-3 py-2"
                        :title="tab.title"
                        :key="tab.id"
                        lazy
                      >
                      </b-tab>
                    </b-tabs>
                  </div>
                  <div class="mt-3">
                    <InputColor v-model="protectionOptions.color" label-text="Cor da fonte" />
                  </div>
                  <div class="mt-3">
                    <b-form-group label="Posição da marca d’água" class="mb-0">
                      <b-form-radio-group v-model="protectionOptions.position" :options="dataPositions" stacked>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                </div>
                <div class="d-md-flex flex-column align-items-center d-none">
                  <span class="pdf-title mb-2">PREVIEW DO PDF</span>
                  <div class="pdfPreview shadow-sm">
                    <span
                      class="_mark"
                      :class="`position_${protectionOptions.position} font_size_${sizes[protectionOptions.size]}`"
                      :style="{ color: protectionOptions.color }"
                    >
                      Liberado para {Nome do aluno} - email do aluno@gmail.com - CPF/CNPJ do aluno
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-4 mt-4">
              <MSwitch
                v-model="protectionOptions.password"
                :disabled="!isProtectionEnabled"
                label-title="Proteja o documento com uma senha. A senha será o endereço de e-mail do comprador."
              />
            </div>
            <MButton
              class="mt-4"
              :label="'Salvar'"
              variant="primary"
              size="md"
              @click="updateCourseProtection()"
              :disabled="isSaving"
            />
          </div>
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PageHeader from '@/components/_structures/PageHeader';
import MSwitch from '@/shared/components/MSwitch.vue';
import InputColor from '@/components/InputColor.vue';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: { PageHeader, MSwitch, InputColor, MButton },
  data() {
    return {
      dataPositions: [
        {
          value: 'top',
          text: 'Topo',
        },
        {
          value: 'left',
          text: 'Esquerda',
        },
        {
          value: 'right',
          text: 'Direita',
        },
        {
          value: 'bottom',
          text: 'Rodapé',
        },
        {
          value: 'middle',
          text: 'Centro/diagonal',
        },
      ],
      isSaving: false,
      isProtectionEnabled: true,
      sizes: ['small', 'medium', 'large'],
      protectionOptions: {
        size: null,
        color: null,
        position: null,
        password: null,
      },
      tabs: [
        {
          value: 0,
          title: 'Pequena',
        },
        {
          value: 1,
          title: 'Média',
        },
        {
          value: 2,
          title: 'Grande',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
    }),
    getFontSizeInString() {
      return this.sizes[this.protectionOptions.size];
    },
  },
  created() {
    this.isProtectionEnabled = this.course?.drm_enabled;
    this.protectionOptions = {
      ...this.course?.drm_options,
      color: this.course?.drm_options?.color || '#000000',
      position: this.course?.drm_options?.position || 'top',
      size: this.course?.drm_options?.size ? this.sizes.findIndex(tab => tab === this.course?.drm_options.size) : 0,
    };
  },
  methods: {
    ...mapActions('course', ['updateCourse']),
    async updateCourseProtection() {
      try {
        this.isSaving = true;
        await this.updateCourse({
          id: this.course.id,
          drm_enabled: this.isProtectionEnabled,
          drm_options: {
            ...this.protectionOptions,
            size: this.getFontSizeInString,
          },
        });
        ToastHelper.successMessage('Configuração de proteção do PDFs atualizada com sucesso!');
      } catch (error) {
        ToastHelper.dangerMessage('Houve um erro ao atualizar a proteção do PDFs!');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.protection-container {
  background: #fff;
}

/deep/ .page-header {
  &__container {
    &-info {
      &-title {
        margin-top: 12px;
      }
    }
  }
}

/deep/ .tabs {
  height: 40px;
  max-width: 250px;
  .card-header-pills,
  .card-header {
    align-items: center;
    height: inherit;
    background-color: #cfcfcf;
    border-radius: 8px;
    padding: 0 1px;
    margin-left: auto;
    margin-right: auto;
  }

  .tab-item {
    color: #262626;
    padding: 0;
    border-radius: 8px;
    &.active {
      background-color: #fff !important;
    }
  }
}

.header-badge {
  color: #262626;
  font-weight: 500;
  font-style: normal;
}

.options-container {
  width: fit-content;
}

/deep/ .timer-input {
  &__content {
    min-width: 180px;
  }
  .input-color-text {
    background: transparent;
  }
}

/deep/ .custom-radio {
  margin-bottom: 12px;
  &:hover {
    .custom-control-label::before {
      border-color: $purple-dark !important;
    }
  }
}

/deep/ .custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: $purple-dark;
  background-color: $purple-dark;
}

/deep/ .custom-control-input:hover ~ .custom-control-label:before {
  border-color: $purple-dark !important;
}

/deep/ .custom-control-input:not(:focus) ~ .custom-control-label:before {
  border: 1px solid #adb5bd;
}

/deep/ .custom-control-input:active ~ .custom-control-label:before {
  background-color: transparent;
}

.pdf-title {
  color: #6f6f6f;
}

.pdfPreview {
  min-width: 227px;
  max-width: 227px;
  min-height: 332px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  text-align: center;
  line-height: 1rem;
  ._mark {
    line-height: 0.5rem;
    position: relative;
    width: max-content;
    top: -4px;
    &.font_size_small {
      font-size: 4px;
    }
    &.font_size_medium {
      font-size: 5px;
    }
    &.font_size_large {
      font-size: 7px;
      top: -1px;
    }
    &.position_left {
      float: left;
      top: 50%;
      transform: rotate(-90deg);
      &.font_size_small {
        left: -30%;
      }
      &.font_size_medium {
        left: -38%;
      }
      &.font_size_large {
        left: -54%;
      }
    }
    &.position_right {
      float: left;
      transform: rotate(90deg);
      top: 50%;
      &.font_size_small {
        left: 63%;
      }
      &.font_size_medium {
        left: 54%;
      }
      &.font_size_large {
        left: 37%;
      }
    }
    &.position_bottom {
      top: 93%;
      &.font_size_large {
        top: 89%;
      }
    }
    &.position_middle {
      float: left;
      top: 50%;
      left: -14%;
      transform: rotate(-56deg);
      &.font_size_small {
        left: 15%;
      }
      &.font_size_medium {
        left: 7%;
      }
      &.font_size_large {
        left: -9%;
      }
    }
  }
}

@media (max-width: 768px) {
  .protection-container {
    background: transparent;
  }

  .options-container {
    width: auto;
  }

  /deep/ .tabs {
    height: 40px;
  }

  /deep/ .timer-input {
    &__content {
      background: #fff;
    }
  }
}
</style>
